<!-- 上架列表 -->
<template>
    <div class="asnBill">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="全部" name="100"></el-tab-pane>
            <el-tab-pane v-for="(item, index) in groundStatus.filter(i => i.value !== '2')" :key="index" 
            :label="item.label" :name="item.value">
            </el-tab-pane>
        </el-tabs>
        <div class="publicSearch p-10">
            <div class="searchBar" :style="{ height: isMoreSearch ? 'auto' : '' }">
                <div>
                    <el-select size="mini" v-model="selectValue">
                        <el-option v-for="(item,index) in orderData" :key="index" :value="item.value" :label="item.label"></el-option>
                    </el-select>
                    <el-input class="ml5" style="width: 300px;" v-model="searchData[selectValue]" size="mini" placeholder="单号搜索，支持输入空格批量搜索" clearable></el-input>
                </div>
                <div v-if="activeName === '100'">
                    <el-select v-model="searchData.listingState" size="mini" filterable placeholder="上架状态" clearable>
                        <el-option v-for="(item, index) in groundStatus" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
                <div>
                    <el-select v-model="searchData.wareType" size="mini" filterable placeholder="入库类型" clearable>
                        <el-option v-for="(item, index) in asnStatusTypes" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
                <div>
                    <el-button @click="getList" size="mini" icon="el-icon-search" type="primary">查询</el-button>
                    <el-button @click="clearSearchData" size="mini" icon="el-icon-refresh">重置</el-button>
                </div>
            </div>
            <!-- <span class="moreSearchBtn" :class="isMoreSearch ? 'el-icon-caret-top' : 'el-icon-caret-bottom'" @click="isMoreSearch = !isMoreSearch"></span> -->
        </div>
        <div class="headerBtns">
            <!-- <el-button size="small" type="primary" :disabled="multipleSelection.length != 1" @click="createGroundBtn">
                上架
            </el-button> -->
            <!-- 上架弹窗 -->
            <el-dialog title="上架" :visible.sync="dialogGroupBox" width="70%" @close="handleClose"  @open="handleOpen" style="margin-top: -10vh;">
                <template slot="title">
                    <div class="flex">
                        <span style="font-size: 20px;">上架</span>
                        <el-tag class="ml10" :type="groundStatus.find(item =>  Number(item.value) === asnMaster.listingState)?.type"
                            v-if="asnMaster.listingState||asnMaster.listingState===0">
                            {{ groundStatus.find(item => Number(item.value) === asnMaster.listingState)?.label }}
                        </el-tag>
                    </div>
                </template>
                <el-form v-model="asnMaster" label-width="80px">
                    <el-row>
                        <el-col :span="5">
                            <el-form-item label="上架单号:">
                                <span>{{ asnMaster.listingBillNo||'-' }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="入库仓:">
                                <span :title="asnMaster.wareName">{{ asnMaster.wareName||'-' }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="入库类型:">
                                <span>{{ formatListDataByKey(asnMaster.wareType, false, asnStatusTypes, 'label', 'value' ) }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="入库单号:">
                                <span :title="asnMaster.asnMasterBillNo">&nbsp;{{ asnMaster.asnMasterBillNo||'-' }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="备注:">
                                <el-tooltip popper-class="custom-tooltip" effect="dark" :content="asnMaster.remark" placement="top" >
                                    <div class="no-wrap">{{asnMaster.remark}}</div>
                                </el-tooltip>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-tabs type="card" v-model="activeName2" @tab-click="handleClickChild">
                        <el-tab-pane label="产品信息" name="first" v-if="formType === 'find'"></el-tab-pane>
                        <div class="tableBox" v-if="activeName2==='first'">
                            <el-table ref="detailTable" height="400" stripe highlight-current-row :data="asnMaster.itemList" style="width: 100%;"
                            @selection-change="handleItemListSelectionChange"
                            :header-cell-style="$parent.tableHeaderColor" border :show-summary="formType==='find'" :summary-method="getSummaries">
                                <el-table-column type="selection" :key="0" width="55" v-if="formType==='edit'"></el-table-column>
                                <el-table-column type="index" label="序号" :key="1" width="50" v-else> </el-table-column>
                                <el-table-column prop="img" :key="4" label="图片">
                                    <template slot-scope="scope">
                                        <el-image style="width: 80px; height: 80px" :src="scope.row.imgUrl" :preview-src-list="[scope.row.imgUrl]">
                                        </el-image>
                                        <!-- <img v-bind:src="scope.row.img_url"  style="width:80px;height:86px"> -->
                                    </template>
                                </el-table-column>
                                <el-table-column show-overflow-tooltip :key="5" prop="commoditySkuCode" label="SKU/产品名称">
                                    <template slot-scope="scope">
                                    <!-- <div
                                        @click="$parent.showPublicModal(scope.row.itemCode, 'item')"
                                    > -->
                                        <span>{{ scope.row.commoditySkuCode }}</span>
                                        <br />
                                        <span>{{ scope.row.skuNameCn }}</span>
                                        <br />
                                    <!-- </div> -->
                                    </template>
                                </el-table-column>
                                <el-table-column show-overflow-tooltip :key="6" prop="listingState" label="状态" v-if="formType==='find'">
                                    <template slot-scope="scope">
                                        <el-tag :type="groundStatus.find(item =>  Number(item.value) === scope.row.listingState)?.type">
                                            {{ groundStatus.find(item => Number(item.value) === scope.row.listingState)?.label }}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column show-overflow-tooltip :key="7" prop="needListQuantity" label="应上架量"></el-table-column>
                                <el-table-column show-overflow-tooltip :key="8" prop="listedQuantity" label="已上架量"></el-table-column>
                                <el-table-column show-overflow-tooltip :key="9" prop="waitListQuantity" label="待上架量">
                                    <template slot-scope="scope">
                                        <div>{{ Number(scope.row.needListQuantity||0) - Number(scope.row.listedQuantity||0) }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column  :key="10" prop="itemInfoList" label="上架数量/上架库位" width="440px" v-if="formType==='edit'">
                                    <template slot-scope="scope">
                                        <div class="flex" v-for="(item, index) in scope.row.itemInfoList" :key="index" :class="{'mt5': index!==0}">
                                            <el-input-number style="width: 120px;" v-model="item.listQuantity" controls-position="right" size="small"
                                                :min="1" :max="scope.row.waitListQuantity"
                                                placeholder="上架数量">
                                            </el-input-number>
                                            <el-select style="width: 210px;" class="ml10" filterable size="small" v-model="item.cellCode" placeholder="上架库位">
                                                <el-option v-for="(iInfo,cellIndex) in getCellInfos(scope.row.itemInfoList)" :key="cellIndex" :label="iInfo.cellCode" :value="iInfo.cellCode">
                                                </el-option>
                                            </el-select>
                                            <el-button 
                                                type="text" class="ml5" v-if="index===0" 
                                                :disabled="scope.row.itemInfoList.reduce((a,b) => Number(a+Number(b.listQuantity||0)), 0) >= scope.row.waitListQuantity" 
                                                @click="addWarePosition(scope.row, item)">新增库位
                                            </el-button>
                                            <el-button 
                                                type="text" class="ml5" style="color: red;" v-if="index!==0" 
                                                @click="deleteWarePosition(scope.row, index)">移除
                                            </el-button>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <template v-if="formType === 'find'">
                            <el-tab-pane label="上架记录" name="third">
                            <div class="tableBox">
                                <el-table highlight-current-row :data="asnMaster.listedRecords" style="width: 100%"
                                :header-cell-style="$parent.tableHeaderColor" border>
                                    <el-table-column prop="img" label="图片">
                                        <template slot-scope="scope">
                                            <el-image style="width: 50px; height: 50px" :src="scope.row.imgUrl" :preview-src-list="[scope.row.imgUrl]">
                                            </el-image>
                                        </template>
                                    </el-table-column>
                                    <el-table-column show-overflow-tooltip prop="commoditySkuCode" label="SKU/产品名称">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.commoditySkuCode }}</span>
                                            <br />
                                            <span>{{ scope.row.skuNameCn }}</span>
                                            <br />
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="listQuantity" label="上架数量"> </el-table-column>
                                    <el-table-column prop="cellCode" label="库位编码"> </el-table-column>
                                    <el-table-column prop="updateTime" label="操作时间"> </el-table-column>
                                    <el-table-column prop="updateUserName" label="操作人"> </el-table-column>
                                </el-table>
                            </div>
                            </el-tab-pane>
                            <!-- <el-tab-pane label="日志" name="2">
                                <div class="tableBox none">
                                    <el-table border highlight-current-row :data="threeDetails" style="width: 100%" :header-cell-style="$parent.tableHeaderColor">
                                        <el-table-column prop="expectQuantity" label="操作时间"> </el-table-column>
                                        <el-table-column prop="expectQuantity" label="操作事项"> </el-table-column>
                                        <el-table-column prop="expectQuantity" label="操作人"> </el-table-column>
                                    </el-table>
                                </div>
                            </el-tab-pane> -->
                        </template>
                    </el-tabs>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogGroupBox = false" size="small">取 消</el-button>
                    <template v-if="formType === 'edit'">
                        <el-button type="primary" size="small" :loading="submitMulLoading" @click="dialogGroupBoxSubmit(0)" :disabled="!itemSelection.length">确 定</el-button>
                        <!-- 部分上架2 才展示 -->
                        <el-button type="danger" size="small" :loading="submitMulLoading" @click="dialogGroupBoxSubmit(1)" v-if="asnMaster.listingState===2">强制完结</el-button>
                    </template>
                </div>
            </el-dialog>
            <!--  v-if="activeName!=='1'" -->
            <el-dropdown class="mgl-10" @command="(v) => openPageVisible(v, false)" :disabled="!multipleSelection.length" v-if="activeName!=='1'">
                <el-button type="primary" icon="iconfont icon-print" size="small" plain :disabled="!multipleSelection.length">
                打印<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="ml10" command="aPrint">打印产品二维码</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <!-- <el-button @click="openTestWareBtn">测试库位选择</el-button> -->
        </div>
        <div class="tableBox">
            <el-table
                ref="singleTable"
                tooltip-effect="dark myTooltips"
                :row-style="$parent.tableRowStyle"
                highlight-current-row
                stripe
                border
                :header-cell-style="$parent.tableHeaderColor"
                v-loading="loading.masterTable"
                :data="asnBills"
                @selection-change="handleSelectionChange"
                style="width: 100%"
                @expand-change="(a,b) => handleExpandChange(a,b, 'item')"
            >
            <!-- row-key="asnMasterBillNo"
                :expand-row-keys="expandRowKeys" -->
                <el-table-column type="selection" :key="0" width="55"></el-table-column>
                <!-- <el-table-column type="index" label="序号" :key="0" width="50"> </el-table-column> -->
                <el-table-column type="expand" :key="1">
                    <template slot-scope="scope">
                        <div class="p-10">
                            <el-table :data="scope.row.itemList" border style="width: 100%">
                                <el-table-column prop="spec" label="图片">
                                    <template slot-scope="scope">
                                        <el-image style="width: 80px; height: 80px" :src="scope.row.imgUrl" :preview-src-list="[scope.row.imgUrl]"> </el-image>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="commoditySkuCode" label="SKU"> </el-table-column>
                                <el-table-column prop="skuNameCn" label="产品名称"> </el-table-column>
                                <el-table-column prop="needListQuantity" label="应上架量"> </el-table-column>
                                <el-table-column prop="listedQuantity" label="已上架量"> </el-table-column>
                                <template v-if="activeName==='0'">
                                    <el-table-column prop="waitListQuantity" label="待上架量"> </el-table-column>
                                    <el-table-column prop="fromCellCode" label="验收库位"> </el-table-column>
                                </template>
                                <el-table-column prop="cellcodes" label="上架库位" v-if="activeName === '1'">
                                    <template slot-scope="scope">
                                        <span v-for="(item,index) in scope.row.cellCodes" :key="index">
                                            {{ item }}
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip :key="2" prop="listingBillNo" label="上架单号" width="170">
                    <template slot-scope="scope">
                        <div>
                            <span class="textBtn" @click="findGroundBtn(scope.row)" >
                                上架单：<span> {{ scope.row.listingBillNo||'-' }} </span>
                            </span>
                        </div>
                        <div>
                            <span class="textBtn" @click="toDetail(scope.row)" >
                                入库单：<span> {{ scope.row.asnMasterBillNo||'-' }} </span>
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip :key="3" prop="listingState" label="上架状态" width="150" v-if="activeName === '100'">
                    <template slot-scope="scope">
                        <el-tag :type="groundStatus.find(item =>  Number(item.value) === scope.row.listingState)?.type">
                            {{ groundStatus.find(item => Number(item.value) === scope.row.listingState)?.label }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip :key="4" prop="rukxx" label="入库信息" width="150">
                    <template slot-scope="scope">
                        <div>入库仓：<span> {{ scope.row.wareName||'-' }}</span></div>
                        <div>入库类型：<span> {{ formatListDataByKey(scope.row.wareType, false, asnStatusTypes, 'label', 'value') }} </span></div>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip :key="5" prop="skuTypeCount" label="SKU信息" width="110" v-if="activeName==='1'"></el-table-column>
                <el-table-column show-overflow-tooltip :key="6" prop="skuTypeCount" label="SKU种类" width="110" v-if="activeName==='0'"></el-table-column>
                <el-table-column show-overflow-tooltip :key="7" prop="needListQuantity" label="应上架量"></el-table-column>
                <el-table-column show-overflow-tooltip :key="8" prop="listedQuantity" label="已上架量"></el-table-column>
                <el-table-column show-overflow-tooltip :key="9" prop="dsNumber" label="待上架量" v-if="activeName==='0'"></el-table-column>
                <el-table-column show-overflow-tooltip :key="10" prop="remark" label="备注" v-if="activeName!=='1'">
                    <template slot-scope="scope">
                        <div style="text-align:left;color:#409EFF;width:250px;height:20px;width:120px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;">
                            <img
                                @click=" memoVisible = true; memoData = scope.row;"
                                style="width:15px"
                                src="../../../../assets/images/bj.png"
                            />
                            {{ scope.row.remark }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip :key="11" prop="createTime" label="创建时间" v-if="activeName!=='100'"></el-table-column>
                <el-table-column show-overflow-tooltip :key="12" prop="listingTime" label="上架时间" v-if="activeName==='1'"></el-table-column>
                <el-table-column show-overflow-tooltip :key="13" prop="wlxx" label="相关时间" width="260" v-if="activeName==='100'">
                    <template slot-scope="scope">
                        <div>创建时间：<span> {{ scope.row.createTime||'-' }} </span></div>
                        <div>收货时间：<span> {{ scope.row.acceptTime||'-' }} </span></div>
                        <div>上架时间：<span> {{ scope.row.listingTime||'-' }} </span></div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" :key="14" fixed="right" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" @click="findGroundBtn(scope.row)">详情</el-button>
                        <el-button class="my-warning" type="text" v-if="scope.row.listingState !== 1" 
                        @click="createGroundBtn(scope.row)"> 上架 </el-button>
                        <!--  v-if="activeName !== '1'&&scope.row.listingState!==1" -->
                        <el-dropdown class="mgl-10" @command="(v) => openPageVisible(v, scope.row)" v-if="activeName !== '1'&&scope.row.listingState!==1">
                            <el-button type="text"> 打印 </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="aPrint">打印产品二维码</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                class="pagination-bottom"
                style="text-align: right"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="searchData.pageNum"
                :page-sizes="[5, 10, 15, 20, 25]"
                :page-size="searchData.pageSize"
                layout="sizes,total, prev, pager, next"
                :total="searchData.total"
            ></el-pagination>
        </div>
        <el-dialog title="备注" :visible.sync="memoVisible" width="30%" @close="getList()">
            <el-form :model="rowData">
                <el-form-item label="备注" label-width="50px">
                    <el-input style="width:350px" type="textarea" rows="4" maxlength="200" show-word-limit v-model="memoData.remark" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="memoVisible = false;getList()">取 消</el-button>
                <el-button type="primary" @click="commitMemo">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 预览pdf -->
        <el-dialog title="预览" :visible.sync="viewVisible" width="50%" @close="getList()" @open="openViewHanlde">
            <el-button @click.prevent.stop="PDFDownload" type="primary" size="small" :disabled="!printObj.srcList.length">下载PDF</el-button>
            <div class="mt20" v-if="printObj.srcList.length">
                <div v-for="(iItem, iIndex) in printObj.srcList" :key="iIndex" style="display: flex;flex-wrap: wrap;align-items: center;">
                    <div v-for="i in iItem.numPages" :key="i" class="ml10">
                        <pdf style="width: 350px;" :src="iItem.url" :page="i" ref="myPdfComponent" v-if="iItem.numPages"></pdf>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="viewVisible = false;getList()">关 闭</el-button>
            </div>
        </el-dialog>
        <!-- 选择库位组件 -->
        <AllShelf ref="allShelfRef" :batch-visible="shelfVisible" @selectWareHandle="selectWareHandle"/>
        <!-- 选择打印尺寸 -->
        <el-dialog title="打印" :visible.sync="viewPageVisible" width="25%" @close="getList()">
            <el-form :model="pageForm" :rules="pageRules">
            <el-form-item label="打印尺寸" prop="chicun">
                <el-radio v-model="pageForm.picSize" label="50*30">50*30</el-radio>
                <el-radio v-model="pageForm.picSize" label="60*40">60*40</el-radio>
            </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="viewPageVisible = false;">取消</el-button>
                <el-button type="primary" @click="pageSubmit()">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import _ from 'lodash'
import { formatListDataByKey } from '@/utils/DateUtils.js'
import { min } from 'lodash';
import Vue from 'vue';
import pdf from 'vue-pdf'
import CMapReaderFactory from 'vue-pdf/src/CMapReaderFactory.js'
import {GetPrintWorld} from '@/utils/PrintWorld.js'
import AllShelf from "@/components/ZhiHuiCompon/AllShelf/index.vue";

export default {
    name: 'AsnBill',
    components: {pdf, AllShelf},
    data() {
        return {
            clientHeight: $(window).height(),
            noShipVisible: false,
            noShipInfo: {},
            memoData: {},
            memoVisible: false,
            asnPrintCode: 10,

            wlVisible: true,
            isMoreSearch: false,
            wareId: PF.getLocal('wareId'),
            wareCode: PF.getLocal('wareCode'),
            loading: {
                masterTable: true,
            },
            rowData: {},
            carrierCode: null,
            searchData: {
                pageNum: 1,
                pageSize: 15,
                total: null,
                keyWords: '',
                wareId: PF.getLocal('wareId', 'number'),
                organizationId: PF.getLocal('organizationId', 'number'),
                listingBillNos: null,
                asnMasterBillNos: null,
                listingState: null,
                wareType: null
            },
            asnBills: [],
            platforms: [],
            carriers: [],
            formLabelWidth: '120px',
            asnUdfHs: [],
            organizations: [],
            suppliers: [],
            multipleSelection: [],
            multipleSelectionChild: [],
            asnTypes: [],
            activeName: '100',
            dialogGroupBox: false,
            loading: {
                masterTable: true,
                toBoxInventorys: false,
            },
            toBoxInventorys: [],
            groupBoxData: {},
            currentRow: {},
            asnMaster: {},
            twoDetails: [],
            threeDetails: [],
            activeName2: 'first',
            expandRowKeys: [],
            cellInfos: [],
            formType: 'find',
            itemSelection: [],
            selectValue: 'listingBillNos',
            orderData: [
                {value: 'listingBillNos', label: '上架单号'},
                {value: 'asnMasterBillNos', label: '入库单号'},
            ],
            cellDataBoxs: [],
            printObj: {
                src: '',
                numPages: 0,
                page: 1,
                currentPage: 0,
                srcList: []
            },
            viewVisible: false,
            srcList: [],
            shelfVisible: false,
            submitMulLoading: false,
            viewPageVisible: false,
            pageForm: {
                picSize: '50*30'
            },
            pageRules: {
                picSize: [{required: true, message: '请选择尺寸', trigger: 'blur'}]
            },
        };
    },
    watch: {
        carrierCode(newVal, oldVal) {
            this.searchData.carrierCode = newVal;
            this.getList();
        },
    },
    computed: {
        ...mapState(['reviewStates', 'groundStatus', 'asnStatusTypes', 'qcStates']),
        getCellInfos() { 
            return (cellCodes) => {
                if (cellCodes && cellCodes.length > 0) {
                    let array = _.filter(this.cellInfos, i => {
                        let a = _.find(cellCodes, ii => ii.cellCode === i.cellCode)
                        return i && !a
                    })
                    return array
                }
                return this.cellInfos
            }
        }
    },
    methods: {
        formatListDataByKey,
        // 选择打印尺寸
        pageSubmit() {
            this.printBtn('aPrint', this.currentRow)
            this.viewPageVisible = false
        },
        openPageVisible(v, row) {
            this.currentRow = row
            this.viewPageVisible = true
        },
        // 选择库位
        selectWareHandle (row) {
            console.log(row, 'row');
            // this.$refs.allShelfRef.close()
        },
        // 打印
        printBtn(command, row) {
            const arrayData = row ? [row] : this.multipleSelection
            // let isPrintLen = _.filter(arrayData||[], item => item.listingState===1)
            // if (isPrintLen.length > 0) {
            //     this.$message.warning('已上架单据不可操作打印!');
            //     return
            // }
            const arrayIds = _.map(arrayData, i => i.listingBillId)
            let url = ''
            if (command === 'aPrint') {//打印产品二维码
                url = '/listingBill/createQRCode'
            }
            window.IOT.showOverlay('打印中～～～');
            IOT.getServerData(url,'post', { listingBillIds: arrayIds, picSize: this.pageForm.picSize }, (ret) => {  
            if (ret.code === 200) {
                let json = {
                    "action":"previewfile", //预览打印  --- printfile 直接打印
                    "format":"pdf_base64",
                    "content": _.map(ret.rows||[], i => i.fileContent),
                    "printer":"",
                    // "cert":"http://zhihuiwms.com/zhihuiwms.com.ctf"
                };
                let pw = GetPrintWorld();   //获取打天下对象 本地
                json.cert = "http://zhihuiwms.com/zhihuiwms.com.ctf"
                pw.Act(json);
                IOT.hideOverlay();
                // let da = ret.rows
                // this.$nextTick(() => {
                //     this.blobUrl = ret.rows
                //     // 针对多个pdf
                //     this.srcList = _.map(this.blobUrl ||[], item => {
                //         return {
                //             url: pdf.createLoadingTask({ url: 'data:application/pdf;base64,' + item.fileContent, CMapReaderFactory }),
                //             fileName: item.fileName
                //         }
                //     })
                //     if (this.srcList.length > 0) {
                //         _.forEach(this.srcList, i => {
                //         if (i.url) {
                //                 i.url.promise.then(pdf => {
                //                     i.numPages = pdf.numPages;
                //                 });
                //             }
                //         })
                //         this.printObj.srcList = this.srcList
                        
                //     }
                //     setTimeout(() => {
                //         IOT.hideOverlay();
                //         this.viewVisible = true
                //     }, 500);
                // })
            } else {
                IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
            }
            })
        },
        openViewHanlde() {
        },
        //  -- base64转Blob文件 --
        dataURLtoBlob(dataurl) {
            var bstr = atob(dataurl)
            var n = bstr.length;
            var u8arr = new Uint8Array(n);
            while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
            }  
            return new Blob([u8arr], { type: 'pdf' });
        },
        base64ToBlob(code, type) {
            code = code.replace(/[\n\r]/g, "");
            let raw = window.atob(code);
            let rawLength = raw.length;
            let uint8Array = new Uint8Array(rawLength);
            for (let i = 0; i < rawLength; i++) {
            uint8Array[i] = raw.charCodeAt(i);
            }
            if ("pdf" === type) {
            return new Blob([uint8Array], { type: "application/pdf" });
            } else {
            return new Blob([uint8Array], { type: "image/jpeg" });
            }
        },
        // -- pdf文件下载 --
        PDFDownload() {
            if (this.blobUrl.length > 0) {
                _.forEach(this.blobUrl, item => {
                    let URL = this.dataURLtoBlob(item.fileContent);
                    var reader = new FileReader();
                    reader.readAsDataURL(URL);
                    reader.onload = function(e) {
                        const a = document.createElement('a');
                        
                        a.download = item.fileName+'.pdf'; // 这里写你的文件名
                        a.href = e.target.result;
                        document.body.appendChild(a)
                        a.click();
                        document.body.removeChild(a)
                    }
                })
            }
        },
        // 入库单详情
        toDetail(row) {
            this.$router.push({
                name: 'asnBillDetail',
                params: {...row, billNo: row.asnMasterBillNo},
            })
        },
        // 修改备注
        commitMemo() {
            var that = this;
            that.memoVisible = false;
            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData(
                '/listingBill/update',
                'post',
                {
                    ...that.memoData,
                    remark: that.memoData.remark,
                },
                ret => {
                    window.IOT.hideOverlay();
                    if (ret.code === 200) {
                        window.IOT.tips('提交成功！', 'success');
                        this.getList();
                    } else {
                        window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
                    }
                }
            );
        },
        // 获取上架列表数据
        getList() {
            var that = this;
            let searchData = PF.JSON(this.searchData);
            that.loading.masterTable = true;
            // .join(',')
            searchData[this.selectValue] = searchData[this.selectValue] ? searchData[this.selectValue].split(/,|，|\s+/) : null
            if (this.selectValue === 'listingBillNos') {
                searchData.asnMasterBillNos = null
            }
            if (this.selectValue === 'asnMasterBillNos') {
                searchData.listingBillNos = null
            }
            let isTab = searchData.listingState==='0'||searchData.listingState==='1'?1:null
            IOT.getServerData('/listingBill/list', 'post', {...searchData, isTab}, ret => {
                that.loading.masterTable = false;
                if (ret.code === 200) {
                    var rows = ret.rows;
                    this.asnBills = _.map(rows, item => {
                        item.dsNumber = Number(item.needListQuantity||0) - Number(item.listedQuantity||0)
                        return item
                    });
                    this.searchData.total = ret.total;
                    this.searchData.pageNum = ret.pageNumber;
                } else {
                    IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
                }
            });
            that.$nextTick(() => {
                that.$refs.singleTable.doLayout(); // 解决表格错位
            });
        },
        // 获取上架库位sku数据
        getAcceptCell() {
            let param = {
                wareId: this.wareId,
                wareCode: this.wareCode,
            };
            // /cell/infos/findByCellUserTypes
            IOT.getServerData('/cell/infos/lableList', 'post', param, (ret) => {
            IOT.hideOverlay();
            if (ret.code === 200) {
                this.cellInfos = _.flatten(_.map(_.filter(ret.rows, i => i.labelCode !== 0), ii => ii.dtoList)); // 过滤调验收位
                console.log(this.cellInfos, 'ret');
                
            } else {
                IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
            }
            });
        },
        // 合计产品信息
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
            if (index === 0) {
                sums[index] = '合计';
                return;
            }
            const values = data.map(item => Number(item[column.property]));
            if (!values.every(value => isNaN(value)) && column.property !== 'listingState') {
                sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                    return prev + curr;
                } else {
                    return prev;
                }
                }, 0);
            } else {
                sums[index] = '';
            }
            });
            return sums;
        },
        //上架弹窗
        handleOpen() {
            IOT.getServerData('/listingBill/detail', 'get', { id: this.currentRow.listingBillId }, ret => {
                if (ret.code === 200) {
                    this.asnMaster = ret.data
                    const itemList = _.map(ret.data.itemList||[], item => {
                        if (!item.itemInfoList) {
                            this.$set(item, 'itemInfoList', [{
                                listingBillItemId: item.listingBillItemId,
                                listQuantity: item.waitListQuantity||null, //上架数
                                cellCode: item.newestCellCode||null
                            }])
                        }
                        return item
                    })
                    if (this.formType === 'edit') { //过滤掉待上架量为0的
                        this.asnMaster.itemList = _.filter(itemList, i => i.waitListQuantity)
                    } else {
                        this.asnMaster.itemList = itemList
                    }
                }
            }, true);
        },
        // 新增库位
        addWarePosition(row, item) {
            row.itemInfoList.push({
                listingBillItemId: row.listingBillItemId,
                listQuantity: null, //上架数
                cellCode: null
            })
        },
        // 移除库位
        deleteWarePosition(row, index) {
            row.itemInfoList.splice(index, 1);
        },
        // 多选change事件
        handleItemListSelectionChange(val) {
            this.itemSelection = val;
        },
        // 确认上架 提交数据
        dialogGroupBoxSubmit(type) {
            // 校验是否可以上架/强制上架
            _.forEach(this.asnMaster.itemList, item => {
                item.sumWait = _.reduce(item.itemInfoList, (a,b) => a+_.toNumber(b.listQuantity||0),0)
            })
            const allItemList2 = _.filter(this.itemSelection, item => item.sumWait > item.waitListQuantity).length
            if (allItemList2 > 0) {
                this.$message.warning('上架数量不能大于待上架量！')
                return
            }
            if (type === 0) {
                const allItemList = _.filter(_.flatten(_.map(this.itemSelection, i => i.itemInfoList)), iItem => !iItem.cellCode||!iItem.listQuantity)
                if (allItemList.length > 0) {
                    this.$message.warning('上架数量/上架库位不能为空！')
                    return
                }
                // 完结
                this.submitMulLoading = true
                window.IOT.showOverlay('提交中～～～');
                IOT.getServerData('/listingBill/confirmList', 'post', {...this.asnMaster, itemList: this.itemSelection}, ret => {
                    window.IOT.hideOverlay();
                    if (ret.code === 200) {
                        window.IOT.tips('提交成功！', 'success');
                        this.dialogGroupBox = false;
                        this.getAcceptCell()
                        this.getList()
                        this.submitMulLoading = false
                    } else {
                        window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
                        this.submitMulLoading = false
                    }
                });
            } else {
                this.submitMulLoading = true
                // 强制完结
                this.$confirm('确定产品已全部上架完成？操作后将更新该入库批次，且无法恢复！' ,'提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    window.IOT.showOverlay('提交中～～～');
                    IOT.getServerData('/listingBill/forceEnd', 'post', this.asnMaster, ret => {
                        window.IOT.hideOverlay();
                        if (ret.code === 200) {
                            window.IOT.tips('提交成功！', 'success');
                            this.dialogGroupBox = false;
                            this.getAcceptCell()
                            this.getList()
                            this.submitMulLoading = false
                        } else {
                            window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
                            this.submitMulLoading = false
                        }
                    });
                    this.dialogGroupBox = false;
                }).catch(() => {
                });
            }
        },
        // 单独点击展开触发
        handleExpandChange(row, expandList, type = 'item') {
            // const obj = _.find(expandList, item => item.billNo === row.billNo)
            // this.expandRowKeys = _.map(expandList || [], (it) => it.billNo);
            // if (expandList.length > 0 && ((type === 'item' && obj) || type === 'all')) {
            //     let url = (url = '/asn/details/list');
            //     IOT.getServerData(url, 'post', { billNo: row.billNo }, ret => {
            //         if (ret.code === 200) {
            //             let list = ret.rows;
            //             let deepAsnBills = _.cloneDeep(this.asnBills)
            //             this.asnBills = _.map(deepAsnBills, i => {
            //                 if (i.billNo == row.billNo) {
            //                     i.details = list
            //                 }
            //                 return i
            //             })
            //         }
            //     });
            // }
        },
        // 操作-上架
        createGroundBtn(row) {
            this.dialogGroupBox = true;
            this.currentRow = row
            this.formType = 'edit'
        },
        // 操作-详情
        findGroundBtn(row) {
            this.dialogGroupBox = true;
            this.currentRow = row
            this.formType = 'find'
        },
        findBoxCode(boxCode, wareId) {
            debugger;
            var that = this;
            that.loading.toBoxInventorys = false;
            that.groupBoxData={
                boxCode : boxCode,
                cellCode:""
            };
 
            IOT.getServerData('/inventorys/findBoxCode', 'get', { boxCode: boxCode, wareId: wareId }, (ret) => {
            that.loading.toBoxInventorys = false;
            if (ret.code === 200) {
                this.toBoxInventorys = ret.rows;
            } else {
                IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
            }
            });
        },
        resetForm(formName) {
            // 重置
            this.$refs[formName].resetFields();
        },
        handleClose(done) {
            // this.resetForm('form');
            this.activeName2 = 'first'
            this.dialogNewVisible = false;
        },
        openTestWareBtn() {
            this.$refs.allShelfRef.open()
        },
        handleClick(tab, event) {
            this.$refs.singleTable.clearSelection()
            this.searchData = {
                pageNum: 1,
                pageSize: 15,
                total: null,
                keyWords: '',
                wareId: PF.getLocal('wareId'),
                wareCode: PF.getLocal('wareCode'),
                organizationId: PF.getLocal('organizationId', 'number'),
                listingBillNos: null,
                asnMasterBillNos: null,
                listingState: null,
                wareType: null
            }
            this.selectValue = 'listingBillNos'
            this.activeName = tab.name
            if (tab.name === '100') {
                this.searchData.listingState = null
            } else {
                this.searchData.listingState = tab.name
            }
            this.getList()
        },
        handleClickChild(tab, event) {
            console.log(tab, event);
        },
        pringAsnCode() {
            window.IOT.getServerData(
                '/asn/codes/print',
                'get',
                { wareId: this.wareId, quantity: this.asnPrintCode },
                ret => {
                    if (ret.code === 200) {
                        var strHTML = ret.data;
                        this.$parent.printHtml(strHTML, null);
                    } else {
                        window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
                    }
                },
                true
            );
        },
        isDelete(asnMasterId) {
            this.$confirm('确认关闭吗？（不会删除）')
                .then(() => {
                    this.deleteOrder(asnMasterId);
                })
                .catch(() => {});
        },
        deleteOrder(asnMasterId) {
            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData('/asn/masters/state6', 'get', { id: asnMasterId }, ret => {
                window.IOT.hideOverlay();
                if (ret.code === 200) {
                    window.IOT.tips('删除成功！', 'success');
                    this.getList();
                } else {
                    window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
                }
            });
        },

        isDeleteDetail(id) {
            this.$confirm('确认关闭吗？ ')
                .then(() => {
                    this.deleteAsnDetail(id);
                })
                .catch(() => {});
        },
        deleteAsnDetail(id) {
            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData('/asn/details/delete', 'get', { id: id }, ret => {
                window.IOT.hideOverlay();
                if (ret.code === 200) {
                    window.IOT.tips('删除成功！', 'success');
                    this.getList();
                } else {
                    window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
                }
            });
        },

        allotAsn() {
            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData('/asn/details/allotAsn', 'get', {}, ret => {
                window.IOT.hideOverlay();
                if (ret.code === 200) {
                } else {
                    window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
                }
            });
        },
        noShipCommit() {
            var noShipInfo = this.noShipInfo;

            window.IOT.showOverlay('提交中～～～');
            IOT.getServerData('/no/ships/add', 'post', noShipInfo, ret => {
                window.IOT.hideOverlay();
                if (ret.code === 200) {
                    window.IOT.tips('提交成功！', 'success');
                    this.noShipVisible = false;
                    this.noShipInfo = {};
                } else {
                }
            });
        },
        getAsnDetailData(billNo) {
            let url = (url = '/asn/details/list');
            IOT.getServerData(url, 'get', { billNo: billNo }, ret => {
                if (ret.code === 200) {
                    let list = ret.rows;
                    this.asnBills.forEach(order => {
                        if (order.billNo == billNo) {
                            order.details = list;
                        }
                    });
                    var asnBills = JSON.parse(JSON.stringify(this.asnBills));
                    this.asnBills = [];
                    this.asnBills = asnBills;
                } else {
                }
            });
        },

        getWlgj(row) {
            window.IOT.getServerData(
                '/kuaidiniaos/jscx',
                'get',
                {
                    expCode: row.carrierCode,
                    expNo: row.shipBillCode,
                },
                ret => {
                    debugger;
                    window.IOT.hideOverlay();
                },
                true
            );
        },
        updateShip(asn) {
            var that = this;
            that.rowData = asn;
            that.shipFormVisible = true;
        },
        commitShip() {
            var that = this;

            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData(
                '/asn/masters/addShipBillCode',
                'post',
                {
                    billNo: that.rowData.billNo,
                    asnMasterId: that.rowData.asnMasterId,
                    shipBillNo: that.rowData.shipBillCode,
                    shipCode: that.rowData.carrierCode,
                },
                ret => {
                    window.IOT.hideOverlay();
                    if (ret.code === 200) {
                        window.IOT.tips('提交成功！', 'success');
                        this.getList();
                    } else {
                        window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
                    }
                }
            );
        },
        // isDelete(asnMasterId) {
        //             this.$confirm('确认删除吗？')
        //                 .then(() => {
        //                     this.deleteOrder(asnMasterId)
        //                 })
        //                 .catch(() => {});
        //         },
        //       deleteOrder(asnMasterId) {
        //           window.IOT.showOverlay('提交中～～～')
        //           window.IOT.getServerData('/asn/masters/delete', 'get', {id: asnMasterId}, (ret) => {
        //               window.IOT.hideOverlay()
        //               if (ret.code === 200) {
        //                   window.IOT.tips('删除成功！', 'success')
        //                   this.getList();
        //               } else {
        //                   window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        //               }
        //           })
        //       },
        handleSizeChange(val) {
            this.searchData.pageSize = val;
            this.getList();
        },

        handleCurrentChange(val) {
            this.searchData.pageNum = val;
            this.getList();
        },
        clearSearchData() {
            this.$refs.singleTable.clearSelection()
            this.searchData = {
                pageNum: 1,
                pageSize: 15,
                total: null,
                keyWords: '',
                wareId: PF.getLocal('wareId'),
                wareCode: PF.getLocal('wareCode'),
                organizationId: PF.getLocal('organizationId', 'number'),
                listingBillNos: null,
                asnMasterBillNos: null,
                listingState: null,
                wareType: null
            }
            this.selectValue = 'listingBillNos'
            this.getList()
        },
        getPlatformData() {
            IOT.getServerData('/platforms/findByWareId', 'get', { wareId: PF.getLocal('wareId', 'number') }, ret => {
                if (ret.code === 200) {
                    this.platforms = ret.rows;
                } else {
                    IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
                }
            });
        },
        getCarrierData() {
            IOT.getServerData('/carriers/allList', 'get', {}, ret => {
                if (ret.code === 200) {
                    this.carriers = ret.rows;
                } else {
                    IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
                }
            });
        },
        resetForm(formName) {
            // 重置
            this.$refs[formName].resetFields();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleSelectionChangeChild(val) {
            this.multipleSelectionChild = val;
        },
        commitFile() {
            IOT.showOverlay('保存中，请稍等...');
            let list = PF.JSON(this.multipleSelection);
            let billNos = [];
            for (let i = 0; i < list.length; i++) {
                billNos.push(list[i].billNo);
            }
            IOT.getServerData('/asn/masters/file', 'post', { billNos: billNos }, ret => {
                IOT.hideOverlay();
                this.multipleSelection = [];
                if (ret.code === 200) {
                    IOT.tips('归档成功！', 'success');
                    this.getList();
                } else {
                    IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
                }
            });
        },
        getCode() {
            var that = this;
            document.onkeypress = function(e) {
                that.getList();
            };
        },
    },
    created() {
        this.getList();
        this.getPlatformData();
        this.getCarrierData();
        PF.getSysParamUdfData('asnUdfHs', [], (data, udfs) => {
            this.asnUdfHs = udfs;
        });
        PF.getOrganizations(rows => {
            this.organizations = rows;
        });
        PF.getSuppliers(rows => {
            this.suppliers = rows;
        });
        PF.getDataDictUdfData('asnType', rows => {
            this.asnTypes = rows;
        });
        PF.getCarriers(rows => {
            this.carriers = rows;
        });
        //   this.getCode();
        this.getAcceptCell()
    },
    mounted() {},
};
</script>

<style scoped lang="less">
.asnBill {
}
:deep(.el-dialog__body) {
    padding-top: 0 !important;
}
</style>
